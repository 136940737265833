import React from 'react';
import './footer.css';

import pcg_logo_long from '../../assets/images/PCG-logo_long.webp';
import pcg_logo_long_safari from '../../assets/images/PCG-logo_long.png';

import { Link } from 'react-router-dom';

const Footer = () => {
  return ( 
  <div className='papercactus__footer'>
    <div className='papercactus__footer-desktop section__xtrasmall__padding'>    
      <div className='papercactus__footer-links'>
        <div className="papercactus__footer-links_logo">
          <picture>
            <source srcSet={pcg_logo_long} type='image/webp' />
            <source srcSet={pcg_logo_long_safari} type='image/png' />
            <img src={pcg_logo_long} alt="lo1go" width='1064px' height='511px'/>
          </picture>
          <p>An Australian Indie Game Company</p>
          <p> SA 5000, Australia</p>
        </div>
        <div className='papercactus__footer-links_div'>
          <h4 className='footer-spacing'>Links</h4>
          <Link to="/"><p>Fox And Shadow</p></Link>
          <Link to="/aboutus"><p>About Us</p></Link>
          <a href="mailto:contact@papercactusgames.com"><p>Contact Us</p></a>
        </div>
        <div className='papercactus__footer-links_div'>
          <h4 className='footer-spacing'>Social Media</h4>
          {/*<a href="https://x.com/PapercactusGame"><p>X</p></a>*/}
          <a href="https://www.youtube.com/channel/UCW4JLT0B1umnFijn8E7s5ZA"><p>YouTube</p></a>
          <a href="https://www.instagram.com/PapercactusGames/"><p>Instagram</p></a>
          <a href="https://discord.com/invite/XuXajDW"><p>Discord</p></a>
        </div>
        <div className='papercactus__footer-links_div'>
          <h4 className='footer-spacing'>Legal</h4>
          <Link to="/legal/privacypolicy"><p>Privacy Policy</p></Link>
          <Link to="/legal/disclaimer"><p>Disclaimer</p></Link>
        </div>
      </div>
      <div className='papercactus__footer-copyright'>
        <p>© 2024 Paper Cactus Games</p>
      </div>
    </div>

    <div className='papercactus__footer-mobile'>
      <div className="papercactus__footer-links_logo">
        <img src={pcg_logo_long} alt="logo" />
        <p>A South Australian Indie Game Company</p>
        <p> SA 5000, Australia</p>
      </div>
      <div className='papercactus__footer-copyright'>
        <p>(c) 2024 Paper Cactus Games</p>
      </div>
    </div>

  </div>

  )
}


export default Footer
